<template></template>

<script>
    export default {
        name: "Logout",
        created() {
            this.$store.state.userData = null;
            this.$store.dispatch("destroyToken").then(response => {
                window.location.replace("/login/1");
            });
        }
    };
</script>
